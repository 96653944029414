import React from "react"
import { Helmet }  from "react-helmet"
import { graphql } from "gatsby"
import '../components/layout.css'
import 'katex/dist/katex.min.css'

//import '../css/blog-post.css'; // make it pretty!

export default function Template({
    data // this prop will be injected by the GraphQL query we'll write in a bit
}) {
    const { markdownRemark: post } = data // data.markdownRemark holds your post data
    return (
        <div className="blog-post-container">
        <div class="block-hero">

        <hr />
        <div>categories: {post.frontmatter.categories.map((cat, i) => [
            <span key={i}>{cat} </span>
        ])}
        <div>tags: {post.frontmatter.tags.map((tag, i) => [
            <span class="small-label" key={i}>{tag} </span>
        ])}
        <hr />

        </div>
        <h1><b>{post.frontmatter.title}</b></h1>
        <Helmet title={`Your Blog Name - ${post.frontmatter.title}`} />
        <div className="blog-post">
        <div></div>
        </div>
        </div>
        <div>BY <span class="blog-author">{post.frontmatter.author}</span></div>
        <hr />
        <div><span class="blog-publish-date">{post.frontmatter.date}</span></div>
        <div>&nbsp;</div>
        <div
        className="blog-post-content"
        dangerouslySetInnerHTML={{ __html: post.html }}
        />
        </div>
        </div>
    )
}

export const pageQuery = graphql`
  query BlogPostByPath($slug:String! ) {
      markdownRemark(frontmatter: { path: { eq: $slug } }) {
          html
          frontmatter {
              date(formatString: "MMMM DD, YYYY")
              path
              title
              author
              categories
              tags
          }
      }
  }
`
//export const pageQuery = graphql`
//  query BlogPostByPath($path: String!) {
//    markdownRemark(frontmatter: { path: { eq: $path } }) {
//      html
//      frontmatter {
//        date(formatString: "MMMM DD, YYYY")
//        path
//        title
//      }
//    }
//  }
//`
